// import { About } from './pages/About'
import Contact from './pages/Contact'
import './assets/css/styles.global.css'
import Qualifications from './pages/Qualifications'
import Footer from './components/Footer'
import Project from './pages/Project'
import { Home } from './pages/Home'
import NavProvider from './components/Navbar/context/NavContext'
import { Nav } from './components/Navbar/nav'


const App = () => {
    return (
        <>
            <NavProvider>
                <Nav />
                <Home />
                {/* <About /> */}
                <Qualifications />
                <Project />
                <Contact />
                <Footer />
            </NavProvider>
        </>
    )
}

export default App
