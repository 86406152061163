import { FC } from 'react'
import { FiGithub } from 'react-icons/fi'
import { BsBoxArrowInRight } from 'react-icons/bs'
import { VscDebugBreakpointLog } from 'react-icons/vsc'
import styled from 'styled-components'

interface ProjectCardInterface {
    name: string
    description: string
    github?: string
    demo?: string
    technologies: string[]
}

const ProjectStyles = styled.div`
    background: var(--color-body-light);
    width: 20rem;
    height: 20rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border-top: 3px solid var(--color-primary);
    box-shadow: var(--shadow-main);
    transition: 0.3s ease-in-out;
    user-select: none;

    &:hover {
        transform: scale(1.025);
    }

    .icon_container {
        text-align: right;
        height: 2rem;

        a {
            font-size: 2rem;
            color: black;

            &:hover {
                color: var(--color-primary);
            }
        }
    }

    .name {
        color: var(--color-titles);
        margin: 0.5rem 0;
        font-family: sans-serif;
    }

    .description {
        color: var(--color-subtitles);
        height: 5rem;
        margin-bottom: 0.6rem;
        overflow-y: scroll;
        font-family: 'Poppins', sans-serif;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .technologies__container {
        height: 4.5rem;

        .technologies {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: left;
            gap: 0.5rem;
            color: var(--color-subtitles);

            .bulletPoint {
                color: var(--color-primary);
            }
        }
    }

    .button {
        width: 7.5rem;
        a {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            font-size: 1.25rem;
            color: black;

            .icon {
                transition: 0.3s ease-in-out;
            }

            &:hover {
                color: var(--color-primary);
            }

            &:hover .icon {
                transform: translateX(0.5rem);
            }
        }
    }
`

export const ProjectCard: FC<ProjectCardInterface> = props => {
    const { name, description, github, demo, technologies } = props

    return (
        <ProjectStyles>
            <div className="project">
                <div className="icon_container">
                    <a href={github} target={'_blank'} rel="noreferrer">
                        {github ? <FiGithub /> : ''}
                    </a>
                </div>
                <h3 className="name">{name}</h3>
                <p className="description">{description}</p>

                <div className="technologies__container">
                    <div className="technologies">
                        {technologies.map(item => (
                            <p key={Math.floor(Math.random() * 10000)}>
                                <VscDebugBreakpointLog className='bulletPoint'/> {item}
                            </p>
                        ))}
                    </div>
                </div>

                <div className="button">
                    <a href={demo || github} target={'_blank'} rel="noreferrer" className="button">
                        <h5>{demo ? 'View More' : 'Visit Repo'}</h5>
                        <BsBoxArrowInRight className="icon" />
                    </a>
                </div>
            </div>
        </ProjectStyles>
    )
}
