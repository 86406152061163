import { FC } from 'react'
import styled from 'styled-components'

interface CertificationCardInterface {
    name: string
    date: string
    description: string
    image: any
    link: string
}

const CertificationStyles = styled.div`
    .cert__container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        user-select: none;
        width: 60rem;
        box-shadow: var(--shadow-main);

        .cert__image {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 2rem;
            height: 8.5rem;
            width: 10rem;
            border-radius: 0.5rem 0 0 0.5rem;
            background: var(--color-body-dark);
            cursor: pointer;

            img {
                transition: 0.3s ease-in-out;
                width: 6.5rem;
                height: auto;
            }

            &:hover img {
                transform: scale(1.1);
            }
        }

        .cert__content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: left;
            padding: 0 2rem;
            height: 8.5rem;
            width: 100%;
            border-radius: 0 0.5rem 0.5rem 0;
            background: var(--color-body-light);

            h3,
            h4 {
                margin-bottom: 0.5rem;
            }

            h3 {
                color: var(--color-titles);
                font-size: 300;
                font-family: sans-serif;
            }

            h4 {
                color: var(--color-primary);
                opacity: 0.9;
                color: black;
                font-size: 1.3rem;
            }

            p {
                color: var(--color-subtitles);
                font-family: 'Poppins', sans-serif;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .cert__container {
            flex-direction: column;
            width: 80vw;
            margin: auto;
            margin-bottom: 3rem;

            .cert__image {
                width: 100%;
                img {
                    width: 5rem;
                }
            }

            .cert__content {
                height: 15rem;
                text-align: center;
            }
        }
    }
`

export const CertificationCard: FC<CertificationCardInterface> = props => {
    const { name, date, description, image, link } = props

    return (
        <CertificationStyles>
            <div className="cert__container">
                <div className="cert__image">
                    <a href={link} target="_blank" rel="noreferrer">
                        <img src={image} alt="" />
                    </a>
                </div>
                <div className="cert__content">
                    <h3>{name}</h3>
                    <h4>{date}</h4>
                    <p>{description}</p>
                </div>
            </div>
        </CertificationStyles>
    )
}
