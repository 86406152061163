import { FC } from 'react'
import styled from 'styled-components'

interface InputInterface {
    text: string
}

const InputSubmitStyles = styled.div`
    input {
        background: var(--color-primary);
        outline: unset;
        border: none;
        border-radius: 0.5rem;
        padding: 1rem;
        font-size: 1.25rem;
        color: var(--color-body);
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            transform: scale(0.95);
            opacity: 1;
        }
    }
`

export const InputSubmit: FC<InputInterface> = ({ text }) => {
    return (
        <InputSubmitStyles>
            <input type="submit" value={text} />
        </InputSubmitStyles>
    )
}
