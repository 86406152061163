import { FC } from 'react'
import { IconType } from 'react-icons'
import styled from 'styled-components'

interface CCInterface {
    icon: IconType
    title: string
    link: string
    text: string
}

const Styles = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    .icon {
        font-size: 2rem;
        a {
            color: var(--color-primary);
        }
    }

    .description {
        h3 {
            font-family: sans-serif;
            color: var(--color-titles);
            margin-bottom: 0.5rem;
            user-select: none;
        }
        
        a {
            color: var(--color-subtitles);
            font-family: 'Poppins', sans-serif;
            font-size: 1rem;
        }
    }
`

const ContactCluster: FC<CCInterface> = ({ icon, title, link, text }) => {
    return (
        <Styles>
            <div className="icon">
                <a href={link}>{icon}</a>
            </div>
            <div className="description">
                <h3>{title}</h3>
                <p>
                    <a href={link}>{text}</a>
                </p>
            </div>
        </Styles>
    )
}

export default ContactCluster
