import { FC } from 'react'
import styled from 'styled-components'

interface Interface {
    text: string
}

const TitleStyles = styled.div`
    text-align: center;
    font-size: 1.5rem;
    color: var(--color-titles);
    /* margin: 2rem 0 0; */
    font-family:Verdana, Geneva, Tahoma, sans-serif ;
`

export const Title: FC<Interface> = ({ text }) => {
    return (
        <TitleStyles>
            <h1>{text}</h1>
        </TitleStyles>
    )
}