const projects = [
    {
        name: 'Vice City Coin',
        description:
            'A way for alows non accrideted investers invest into Miami through Proof of Stake and a DAO.',
        github: 'https://github.com/stlyed/miamihackweek2022',
        demo: 'https://devpost.com/software/vice-city-coin',
        technologies: ['Solana', 'Express.js', 'MongoDB', 'React', 'Rust'],
    },
    {
        name: 'Alarm Discord Bot',
        description:
            'An interactive bot that will set an alarm with the time provided. When the time comes, it will notify you.',
        github: 'https://github.com/stlyed/alarm_discord_bot',
        demo: null,
        technologies: ['Python', 'AWS DynamoDB'],
    },
    {
        name: 'Portfollio Website',
        description: 'A website which keeps track of my professional progress.',
        github: null,
        demo: 'https://smyvens.com/',
        technologies: ['React', 'AWS S3', 'CloudFront'],
    },
    {
        name: 'Snake Game',
        description:
            'A game where you are a snake, your objective is to grow by eating a fruit. Be careful, as touching anything which is not a fruit will not end well. You may recall playing this game on your old Nokia.',
        github: 'https://github.com/stlyed/SnakeGame',
        demo: null,
        technologies: ['Python'],
    },
]

export default projects
