import NavLink from './NavLink'
import { useState } from 'react'
import { useEffect } from 'react'
import { navLinks } from './navLinks'
import './Nav.css'

const Nav = () => {
	const [transparent, setTransparent] = useState(true)
    const [scrollPos, setScrollPos] = useState(0)
    const [showNavbar, setShowNavbar] = useState(true)

    const controlNavbar = () => {
        // transition between transparent and opaque navbar
        if (window.scrollY > 100) {
            setTransparent(false)
        } else {
            setTransparent(true)
        }

        // hide navbar when scrolling down
        if (scrollPos < window.scrollY && window.scrollY > 100) {
            setShowNavbar(false)
        } else {
            setShowNavbar(true)
        }
        setScrollPos(window.scrollY)
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    })

    return (
        <nav className={`${transparent ? 'transparent' : ''} ${showNavbar ? '' : 'hide'}`}>
            <div className="navbar">
                {navLinks.map(({ navLinkId, scrollToId }, idx) => (
                    <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
                ))}
            </div>
        </nav>
    )
}

export default Nav
