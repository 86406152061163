import { FC } from 'react'
import styled from 'styled-components'

interface InputInterface {
    text: string
    columns: number
    rows: number
    action: React.Dispatch<React.SetStateAction<string>>
}

const InputTextAreaStyles = styled.div`
    div {
        background: white;
        border-radius: 0.5rem;
        padding: 0.75rem 1rem 0.25rem;
        margin-bottom: 1rem;

        label {
            font-family: 'Poppins', sans-serif;
        }

        textarea {
            width: 100%;
            background: white;
            border: none;
            outline: none;
            padding: 0.25rem 0.5rem 0.5rem 0;
            font-size: 1rem;
            color: var(--color-subtitles);
            resize: none;
        }
    }
`

export const InputTextArea: FC<InputInterface> = ({ columns, rows, text, action }) => {
    return (
        <InputTextAreaStyles>
            <div>
                <label>{text}</label>
                <textarea
                    cols={columns}
                    rows={rows}
                    onChange={e => action(e.target.value)}
                ></textarea>
            </div>
        </InputTextAreaStyles>
    )
}