import { useState } from 'react'
import { useNav } from '../components/Navbar/customHooks/useNav'
import { CertificationCard, TimelineCard } from '../components/Cards'
import { SubTitle, Title } from '../components/Section'
import certifications from '../assets/data/certifications'
import styled from 'styled-components'
import experience from '../assets/data/experience'
import education from '../assets/data/education'

const QualificationsStyles = styled.div`
    .tabs__titles {
        display: flex;
        justify-content: center;
        gap: 4rem;
        text-align: center;

        h1 {
            cursor: pointer;
            font-size: 1.5rem;
            padding: 0.5rem 1rem;
            border: 1px solid transparent;
            border-radius: 0.5rem;
            color: var(--color-titles);
            width: 12rem;
            transition: all ease-in-out 0.2s;
            user-select: none;

            &:hover {
                background: var(--color-body-dark);
                border-bottom: 1px solid var(--color-primary);
            }

            &:active {
                transform: scale(0.95);
                opacity: 1;
            }

            &.active {
                color: var(--color-primary);
                background: var(--color-body-dark);
                border-bottom: 1px solid var(--color-primary);
                box-shadow: var(--shadow-main);
            }

            &.active:hover {
                color: var(--color-titles);
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2rem 0 4rem;

    }
    .subsection__title {
        display: none;
    }
    
    .hide {
        display: none;
    }

    @media screen and (max-width: 768px) {
        .tabs__titles {
            display: none;
        }

        .hide {
            display: unset;
        }

        .content {
            margin: unset;
        }

        .subsection__title {
            display: block;
            text-align: center;
            color: var(--color-titles);
            margin: 4rem 0 1rem;
            color: var(--color-primary);
            font-family: sans-serif;
        }
    }
`

const Qualifications = () => {
    const [tabIndex, setTabIndex] = useState(1)
    const qualificationsRef = useNav('Qualifications')

    return (
        <QualificationsStyles>
            <section className="qualifications__section" ref={qualificationsRef} id="qualifications">
                <Title text="Qualifications" />
                <SubTitle text="My eligibility" />

                {/* the title of each tabs */}
                <div className="tabs">
                    <div className="tabs__titles">
                        <h1 className={tabIndex === 0 ? 'active' : ''} onClick={() => setTabIndex(0)}>
                            Education
                        </h1>
                        <h1 className={tabIndex === 1 ? 'active' : ''} onClick={() => setTabIndex(1)}>
                            Experience
                        </h1>
                        <h1 className={tabIndex === 2 ? 'active' : ''} onClick={() => setTabIndex(2)}>
                            Certifications
                        </h1>
                    </div>

                    {/* education section */}
                        <h1 className="subsection__title">Education</h1>
                    <div className={`${tabIndex === 0 ? 'content' : 'hide'} subsection`}>
                        {education.map(edu => (
                            <TimelineCard key={edu.title} {...edu} description={edu.date} className="timeline" />
                        ))}
                    </div>

                    {/* experience section */}
                        <h1 className="subsection__title">Experience</h1>
                    <div className={`${tabIndex === 1 ? 'content' : 'hide'} subsection`}>
                        {experience.map(exp => (
                            <TimelineCard key={exp.title} {...exp} className="timeline" />
                            ))}
                    </div>

                    {/* certification section */}
                            <h1 className="subsection__title">Certifications</h1>
                    <div className={`${tabIndex === 2 ? 'content' : 'hide'} subsection`}>
                        {certifications.map(cert => (
                            <CertificationCard key={cert.name} {...cert} className="certification" />
                        ))}
                    </div>
                </div>
            </section>
        </QualificationsStyles>
    )
}

export default Qualifications
