import React, { useContext } from 'react'
import { NavContext } from '../context/NavContext'

const NavLink = ({ navLinkId, scrollToId }) => {
    const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext)

    const handleClick = () => {
        setActiveNavLinkId(navLinkId)
        document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div
            className={`navlink__container ${activeNavLinkId === navLinkId ? 'activeClass' : ''}`}
            onClick={handleClick}
        >
            <h3 id={navLinkId}>{navLinkId}</h3>
        </div>
    )
}

export default NavLink
