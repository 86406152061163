import styled from 'styled-components'

const FooterStyles = styled.div`
    width: 100vw;
    padding: 3rem 0;

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .title {
        color: var(--color-titles);
        margin: 0.5rem 0 1.5rem;
        font-size: 2.5rem;
    }

    .links {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-subtitles);
    }

    .socials {
        margin-bottom: 1.75rem;
    }

    .links {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        gap: 2.6rem;

        h5 {
            color: var(--color-subtitles);
            cursor: pointer;
            font-family: 'Poppins', sans-serif;

            &:hover {
                color: var(--color-primary);
                border-color: var(--color-primary);
            }
        }
    }

    .line {
        background: var(--color-subtitles);
        height: 3px;
        width: 30rem;
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        width: 100%;

        .line{
            display: none;
        }
        .links {
            flex-direction: column;
            gap: 1rem;
        }
    }
`

const Footer = () => {
    const scrollToSection = (section: string) => {
        // @ts-ignore: Object is possibly 'null'.
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <FooterStyles>
            <div className="footer">
                <h3 className="title">Smyvens Estime</h3>
                <div className="links">
                    <h5 onClick={() => scrollToSection('home')}>Home</h5>
                    <h5 onClick={() => scrollToSection('qualifications')}>Qualifications</h5>
                    <h5 onClick={() => scrollToSection('projects')}>Projects</h5>
                    <h5 onClick={() => scrollToSection('contact')}>Contact</h5>
                </div>
                <div className="line"></div>
            </div>
        </FooterStyles>
    )
}

export default Footer
