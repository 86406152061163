import { Title, SubTitle } from '../components/Section'
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai'
import { GrLinkedinOption } from 'react-icons/gr'
import { InputField, InputSubmit, InputTextArea } from '../components/Input'
import { useState, useRef } from 'react'
import { useNav } from '../components/Navbar/customHooks/useNav'
import styled from 'styled-components'
import ClosePopup from '../components/ClosePopup'
import ContactCluster from '../components/ContactCluster'
import FeedbackCard from '../components/Cards/FeedbackCard'

const apiLink = 'https://5oys0bjd50.execute-api.us-east-1.amazonaws.com/prod/contact-me'

const ContactStyled = styled.div`
    margin: auto;
    max-width: 90rem;
    margin-bottom: 5rem;

    .contact__container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 7.5rem;
        gap: 5rem;
    }

    .hide {
        display: none;
    }

    .loader__container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .loader {
            border: 16px solid var(--color-subtitles);
            border-radius: 50%;
            border-top: 16px solid var(--color-primary);
            width: 120px;
            height: 120px;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
        }
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    @media screen and (max-width: 768px) {
        .contact__container {
            flex-direction: column;
            padding: 0 3rem;
        }
        .button {
            text-align: center;
        }
    }
`

const Contact = () => {
    const contactRef = useNav('Contact')

    const [name, setName] = useState('')
    const [email, setEamil] = useState('')
    const [message, setMessage] = useState('')

    const [loadingSpinner, setLoadingSpinner] = useState(false)
    const [feedbackMessage, setFeedbackMessage] = useState(null)
    const [showFeedbackMessage, setShowFeedbackMessage] = useState('hide')

    const feedBackRef = useRef(null)
    ClosePopup(feedBackRef, () => setShowFeedbackMessage('hide'))

    const sendMessage = async e => {
        const response = await fetch(apiLink, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message,
            }),
        })
        return response.json()
    }

    const submitForm = async e => {
        e.preventDefault()
        setLoadingSpinner(true)
        if (name && email && message) {
            sendMessage()
                .then(() => {
                    setFeedbackMessage(['Message Sent!', true])
                    setLoadingSpinner(false)
                    setShowFeedbackMessage('')
                })
                .catch(() => {
                    setFeedbackMessage(['An Error Occured!', false])
                    setLoadingSpinner(false)
                    setShowFeedbackMessage('')
                })
        } else {
            setFeedbackMessage(['Complete the form to send message!', false])
            setLoadingSpinner(false)
            setShowFeedbackMessage('')
        }
    }

    return (
        <ContactStyled>
            <section className="contact__section" ref={contactRef} id="contact">
                <Title text="Send me a message" />
                <SubTitle text="Let's network" />

                <div className="contact__container">
                    <div className="contact__icons">
                        <ContactCluster
                            icon={<AiOutlinePhone />}
                            title="Phone"
                            link="tel:+19415298606"
                            text="941-529-8606"
                        />
                        <ContactCluster
                            icon={<AiOutlineMail />}
                            title="Email"
                            link="mailto:smyvensestime@gmail.com"
                            text="smyvensestime@gmail.com"
                        />
                        <ContactCluster
                            icon={<GrLinkedinOption />}
                            title="Linkedin"
                            link="https://www.linkedin.com/in/smyvens/"
                            text="@smyvens"
                        />
                    </div>
                    <div className="contact__form">
                        <form onSubmit={submitForm} id="message__form">
                            <InputField text="Name" action={setName} />
                            <InputField text="Email" action={setEamil} />
                            <InputTextArea columns={50} rows={10} text="Message" action={setMessage} />
                            <div className="button">
                                <InputSubmit text="Send Message" className="" />
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {/* popup that shows when user clicks send message */}
            <div className={showFeedbackMessage} ref={feedBackRef}>
                {feedbackMessage ? (
                    <FeedbackCard
                        message={feedbackMessage[0]}
                        negative={!feedbackMessage[1]}
                        action={() => setShowFeedbackMessage('hide')}
                    />
                ) : (
                    ''
                )}
            </div>
            {/* loading animation when sending message */}
            <div className={`loader__container ${loadingSpinner ? '' : 'hide'}`}>
                <div className="loader"></div>
            </div>
        </ContactStyled>
    )
}

export default Contact
