import { FC } from 'react'
import styled from 'styled-components'

interface InputInterface {
    text: string
    action: React.Dispatch<React.SetStateAction<string>>
}

const InputFieldStyles = styled.div`
    div {
        background: white;
        border-radius: .5rem;
        padding: .75rem 1rem .25rem;
        margin-bottom: 1rem;

        label {
            font-family: 'Poppins', sans-serif;
        }
        
        input {
            width: 100%;
            background: white;
            border: none;
            outline: none;
            padding: .25rem .5rem .5rem 0;
            font-size: 1rem;
            color: var(--color-subtitles);
        }
    }
`

export const InputField: FC<InputInterface> = ({ text, action }) => {
    return (
        <InputFieldStyles>
            <div>
                <label>{text}</label>
                <input type="text" onChange={e => action(e.target.value)}/>
            </div>
        </InputFieldStyles>
    )
}
