import { FC } from 'react'
import { VscDebugBreakpointLog } from 'react-icons/vsc'
import styled from 'styled-components'

interface TimelineCardInterface {
    title: string
    subtitle: string
    keyPoints?: Array<string>
    description?: string
}

const TimelineCardStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    .timeline__container {
        background: var(--color-body-light);
        border-left: 3px solid var(--color-primary);
        width: 50rem;
        padding-left: 2rem;
        border-radius: 0 0.5rem 0.5rem 0;
        box-shadow: var(--shadow-main);

        h3 {
            color: var(--color-titles);
            margin: 1rem 0.5rem .5rem;
            font-family: sans-serif;
        }

        h4 {
            color: black;
            padding-left: 0.4rem;
            margin-bottom: 0.5rem;
            font-size: 1.5rem;
        }

        div {
            color: var(--color-subtitles);
            padding-left: 0.4rem;
            margin-bottom: 1.25rem;

            .bulletPoint {
                margin-right: 1rem;
                color: var(--color-primary);
            }
            
            p {
                margin-top: 0.5rem;
                font-family: 'Poppins', sans-serif;
            }
        }
    }

    @media screen and (max-width: 768px) {
        width: 80vw;
        margin: 0 auto 2rem;
    }
`

export const TimelineCard: FC<TimelineCardInterface> = props => {
    const { title, subtitle, keyPoints, description } = props

    return (
        <TimelineCardStyles>
            <div className="timeline__container">
                <h3>{title}</h3>
                <h4>{subtitle}</h4>
                <div>
                    {keyPoints ? (
                        keyPoints.map(point => (
                            <p key={Math.floor(Math.random() * 10000)}>
                                <VscDebugBreakpointLog className="bulletPoint" />
                                {point}
                            </p>
                        ))
                    ) : (
                        <p>{description}</p>
                    )}
                </div>
            </div>
        </TimelineCardStyles>
    )
}
