const education = [
    {
        title: 'BS, Computer Science',
        subtitle: 'Florida International University (FIU)',
        date: '2022 - 2024'
    },
    {
        title: 'AA, Computer Science',
        subtitle: 'Miami Dade College (MDC)',
        date: '2020 - 2022'
    },
    {
        title: 'High School Diploma',
        subtitle: 'North Miami Beach Senior High School',
        date: '2016 - 2020'
    }
]

export default education