import styled from 'styled-components'
import { useNav } from '../components/Navbar/customHooks/useNav'
import { SubTitle, Title } from '../components/Section'
import Socials from '../components/Socials'
const homePicture = require('../assets/img/custom-backdrop.png')

const HomeStyles = styled.div`
    .home__section {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: row-reverse;
        height: calc(100vh - 5rem);
        margin-bottom: 5rem;
    }

    .home__picture {
        height: 25rem;
        width: 25rem;
        object-fit: cover;
        object-position: 0 0;
        transform: scaleX(-1);
        border-radius: 50%;
        box-shadow: var(--shadow-main);
        transition: all 1s ease-in-out;

        &:hover {
            border-radius: 1rem;
            transform: scale(-1.5, 1.5);
        }
    }

    .decription__container {
        width: 30rem;
        font-size: 1.25rem;
        font-family: 'Poppins', sans-serif;
        text-align: center;
        color: var(--color-subtitles);

        .description {
            opacity: 0.4;
        }

        .socials {
            margin-top: 2rem;
        }
    }
    @media screen and (max-width: 1160px) {
        .home__picture {
            position: relative;
            width: 35vw;
            height: 35vw;
        }

        .decription__container {
            max-width: 50vw;
        }
    }

    @media screen and (min-width: 1160px) {
        .home__picture {
            width: 40vw;
            height: 40vw;
            transition: all 0.5s ease-in-out;

            &:hover {
                border-radius: 50%;
                box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
                transform: scale(-1.05, 1.05);
            }
        }
    }

    @media screen and (max-width: 768px) {
        .home__section {
            flex-direction: column;
            gap: 2rem;
            margin: 5rem 0 3rem;
            height: auto;
        }

        .home__picture {
            width: 90vw;
            height: 90vw;

            &:hover {
                border-radius: 50%;
                transform: scale(-1, 1);
            }
        }

        .decription__container {
            font-size: 1.1rem;
            max-width: 90vw;
        }
    }

`

export const Home = () => {
    const homeRef = useNav('Home')

    return (
        <HomeStyles>
            <section className="home__section" ref={homeRef} id="home">
                <div className="picture__socials">
                    <a href="/resume/">
                        <img className="home__picture" src={homePicture} alt="" />
                    </a>
                </div>
                <div>
                    <Title text="Hi, I'm Smyvens" />
                    <SubTitle text="Student - Computer Science @ MDC" margin="0 0 .5rem" />

                    <div className="decription__container">
                        <p className="description">
                            Creative individual with a passion for learning new technologies and a very strong
                            interest in computer science; Seeking a Computer Science degree at Miami Dade
                            College North campus.
                        </p>
                        <div className="socials">
                            <Socials />
                        </div>
                    </div>
                </div>
            </section>
        </HomeStyles>
    )
}
