const experience = [
    {
        title: 'Incoming Solution Architect Intern',
        subtitle: 'June 2022 - September 2022 | Amazon Web Services',
        keyPoints: [
            'Performed very well during previous AWS Internship and received an offer letter to return again.',
        ],
    },
    {
        title: 'Solution Architect Intern',
        subtitle: 'June 2021 - Agust 2022 | Amazon Web Services',
        keyPoints: [
            'Learned about AWS cloud and built infrastructures and applications using AWS solutions.',
            "Designed and deployed a resilliant infrastructure on the AWS cloud.",
            'Utilize auto scaling groups to dynamically scale the infrastructure based on load.',
            'Utilize MySQL through RDS and S3 to store data.',
        ],
    },
    {
        title: 'Librian Intern',
        subtitle: 'August 2018 - June 2019 | North Miami Beach Library',
        keyPoints: [
            'Responsible for stacking materials in their respective shelves.',
            'Guide customers navigate throughout the different sections of the library.',
        ],
    },
    
]

export default experience
