const certifications = [
    {
        name: 'AWS Certified Solutions Architect Associate',
        date: 'August 27, 2021',
        description:
            'For being able to build a secure and reliable applications on the AWS platform with a highly available and scalable design on an AWS server.',
        image: require('../img/aws-certified-solutions-architect-associate.png'),
        link: 'https://www.credly.com/badges/386af06c-f4c9-426d-a52e-e73a405d5aa5/public_url',
    },
    {
        name: 'AWS Certified Cloud Practitioner',
        date: ' July 04, 2021',
        description:
            'For being able to describe: the key services on the AWS platform and their common use cases, the billing, account management, and pricing models.',
        image: require('../img/aws-certified-cloud-practitioner.png'),
        link: 'https://www.credly.com/badges/8b8f5cd2-daa3-49b8-b9ca-71bd1e0d429b/public_url',
    },
    {
        name: 'HTML5 Application Development Fundamentals',
        date: 'July 24, 2019',
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, asperiores.',
        image: require('../img/mta-html5-application-development-fundamentals-certified-2019.png'),
        link: 'https://www.credly.com/badges/35f241f7-65da-4689-b059-06edae117a3b/public_url',
    },
]

export default certifications
