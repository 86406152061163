import styled from 'styled-components'
import projects from '../assets/data/projects'
import { ProjectCard } from '../components/Cards'
import { useNav } from '../components/Navbar/customHooks/useNav'
import { SubTitle, Title } from '../components/Section'

const ProjectStyles = styled.div`
    .projects__section {
        max-width: 64rem;
        margin: auto;
        margin-bottom: 5rem;
    }

    .projects__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        grid-gap: 2rem;
    }

    @media screen and (max-width: 1100px) {
        .projects__container {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    
    @media screen and (max-width: 768px) {
        .projects__container {
            grid-template-columns: 1fr;
        }
    }
`
const Project = () => {
    const projectRef = useNav('Projects')

    return (
        <ProjectStyles>
            <section className="projects__section" ref={projectRef} id="projects">
                <Title text="Projects" />
                <SubTitle text="Some of my work" />

                <div className="projects__container">
                    {projects.map(item => (
                        <ProjectCard key={item.name} {...item} className="project__item" />
                    ))}
                </div>
            </section>
        </ProjectStyles>
    )
}

export default Project
