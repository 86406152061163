import { FC } from "react"
import styled from "styled-components"

interface Interface {
    text: string
    margin?: string
}

const SubTitleStyles = styled.div`
    text-align: center;
    font-size: .75rem;
    color: var(--color-subtitles);
    font-family: "Poppins", sans-serif;
`

export const SubTitle: FC<Interface> = ({ text, margin='0 0 3rem' }) => {
    return (
        <SubTitleStyles style={{margin: `${margin}`}}>
            <h2>{text}</h2>
        </SubTitleStyles>
    )
}