import { FC } from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import styled from 'styled-components'

/**
 * @interface FeedbackCardInterface
 * @param {React.SetStateAction<string>} action - What needs to happen when user clicks okay,
 * typically an arrow function that close this component
 * @param {string} [message] - The message that is showned in the card,
 * You can optionally write it in the children as an alternative
 * @param {boolean} [negative=False] - Wheter to show a cross with red colored text
 * or a checkmark with green text
 */
interface FeedbackCardInterface {
    action: React.SetStateAction<string>
    message?: string
    negative?: boolean
}

const FeedbackCardStyles = styled.div`
    .card {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 1.5rem;

        border-radius: 1rem;
        min-width: 30rem;
        max-width: 80vw;
        padding: 5rem;
        background:#15191f63;
        border: 1px solid grey;
        /* background: var(--color); */
        backdrop-filter: blur(9px);

        box-shadow: var(--shadow-main);
    }

    .icon {
        font-size: 8rem;
    }

    .message {
        font-family: sans-serif;
        font-size: 1.5rem;
    }

    .button {
        font-family: 'poppins', sans-serif;
        font-size: 1rem;
        background-color: var(--color-titles);
        padding: 1rem;
        border-radius: 0.5rem;
        cursor: pointer;
    }

    .positive {
        color: green;
    }

    .negative {
        color: red;
    }

    @media screen and (max-width: 550px) {
    }
`

const FeedbackCard: FC<FeedbackCardInterface> = ({ message, negative, action, children }) => {
    return (
        <FeedbackCardStyles>
            <div className="card">
                <div className={`icon ${negative ? 'negative' : 'positive'}`}>
                    {negative ? <IoIosCloseCircleOutline /> : <AiOutlineCheckCircle />}
                </div>
                <p className={`message ${negative ? 'negative' : 'positive'}`}>{message || children}</p>
                {/* @ts-ignore: Type 'string' is not assignable to type 'MouseEventHandler<HTMLDivElement> | undefined'. */}
                <div className={`button ${negative ? 'negative' : 'positive'}`} onClick={action}>
                    Okay
                </div>
            </div>
        </FeedbackCardStyles>
    )
}

export default FeedbackCard
